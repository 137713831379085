import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {ApiService} from '../service/api.service';
import {DatePipe} from '@angular/common';
import {HttpClient, HttpHeaders} from '@angular/common/http';

declare const Date: any;
@Component({
    selector: 'app-stats2',
    templateUrl: './stats2.component.html',
    styleUrls: ['./stats2.component.css']
})
export class Stats2Component implements OnInit {

    new_rate;
    new_rate_usd;

    data_curr_eur = 0;
    data_curr_usd = 0;


    charge_gains = 0;
    gain_eur = 0;
    gain_usd = 0;

    gains_v1 = 0;
    transfered_v1 = 0;
    fees_v1 = 0;
    pending_v1 = 0;

    gains_v2 = 0;
    transfered_v2 = 0;
    fees_v2 = 0;
    pending_v2 = 0;

    fedapay_pending = 0;
    fedapay_pending_data = [];
    fedapay_pending_fees = 0;



    flutter24_pending = 0;
    flutter24_pending_data = [];
    flutter24_pending_fees = 0;

    flutter48_pending = 0;
    flutter48_pending_data = [];
    flutter48_pending_fees = 0;


    digipay_pending = 0;
    digipay_pending_data = [];
    digipay_pending_fees = 0;

    //Fedapay : 44
    // Paydunya : 3

    base_url = 'https://api.payment.kolo-pay.com/v1/';


    date = this.datePipe.transform(new Date(), 'dd/MM/yyyy');
    date2 = this.datePipe.transform(new Date(), 'yyyy-MM-dd');//"2021-12-03";
    date3 = this.datePipe.transform( new Date(), 'yyyy-MM-dd');//"2021-12-03";
    dates = ['2021-12-04', '2021-12-05', '2021-12-06', '2021-12-07', '2021-12-08', '2021-12-09', '2021-12-10', '2021-12-11', '2021-12-12'];
    res: any = [];

    histories = [];
    data = [];

    our_eur_rates = 0;
    our_usd_rates = 0;

    constructor(public afs: AngularFirestore,
                public api: ApiService,
                public datePipe: DatePipe,
                private http: HttpClient) {
    }

    async ngOnInit() {
        /*this.our_eur_rates = (await this.api.performGetPromise("http://api.cosna-afrique.com/rates.php?from=EUR&to=XOF&amount=1", null)).data.to.amount;
        this.our_usd_rates = (await this.api.performGetPromise("http://api.cosna-afrique.com/rates.php?from=USD&to=XOF&amount=1", null)).data.to.amount;
        console.log(this.our_eur_rates);
        console.log(this.our_usd_rates);


        const new_rate = await this.api.performGetPromise('https://api.fastforex.io/convert?from=EUR&to=XOF&amount=1&api_key=d1e58e5969-fc3ca7754a-r5x7zz', null);
        this.new_rate = new_rate.result.rate;
        console.log(new_rate);
        const new_rate_usd = await this.api.performGetPromise('https://api.fastforex.io/convert?from=USD&to=XOF&amount=1&api_key=d1e58e5969-fc3ca7754a-r5x7zz', null);
        this.new_rate_usd = new_rate_usd.result.rate;
        console.log(new_rate_usd);*/
        Date.prototype.addDays = function (days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };
        this.date3 = this.datePipe.transform( new Date().addDays(1), 'yyyy-MM-dd');
        //this.calcGainsv1();
        //this.getFedaPayTransactions();
        //this.getFlutter24Transactions();
        //this.getFlutter48Transactions();
        //this.getDigipayTransactions();
        this.calcGainsv2();
        //setInterval(() => {
        /*this.pending_v1 = 0;
        var nodesSnapshot :any = document.evaluate("//tr[@style='background: red; display: table-row;']/td[@class='amount']", document, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null );
        for ( var i=0 ; i < nodesSnapshot.snapshotLength; i++ ){
            this.pending_v1 += parseInt( nodesSnapshot.snapshotItem(i).innerText );

        }

        this.fees_v1 = this.pending_v1 * 0.008;
        console.log(this.pending_v1);*/
        //    this.calcGainsv2();
        //}, 30000);
    }

    async getFedaPayTransactions() {
        //this.dates.forEach(async dte => {

        let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date2 + ';operator_id:44;status:PAID&limit=0&searchJoin=and&searchFields=status:=&include=user');
        this.fedapay_pending = 0;
        this.fedapay_pending_data = snapshots.data;
        snapshots.data.forEach(async (d: any) => {
            this.fedapay_pending += Math.round(parseFloat(d.converted_amount));
        });
        this.fedapay_pending_fees = Math.round(0.003 * this.fedapay_pending);
        console.log(this.fedapay_pending);
        console.log(this.fedapay_pending_fees);
        console.log(this.fedapay_pending_data);

    }
    async getFlutter24Transactions() {
        //this.dates.forEach(async dte => {

        //new Date(new Date().setDate(new Date().getDate()-1))
        let dt = this.datePipe.transform(new Date(new Date().setDate(new Date().getDate()-0)), 'yyyy-MM-dd');
        let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + dt + ';operator_id:38;status:PAID&limit=0&searchJoin=and&searchFields=status:=&include=user');
        this.flutter24_pending = 0;
        this.flutter24_pending_data = snapshots.data;
        snapshots.data.forEach(async (d: any) => {
            this.flutter24_pending += Math.round(parseFloat(d.converted_amount));
        });
        this.flutter24_pending_fees = Math.round(0.003 * this.flutter24_pending);
        console.log(this.flutter24_pending);
        console.log(this.flutter24_pending_fees);
        console.log(this.flutter24_pending_data);

    }

    async getFlutter48Transactions() {
        //this.dates.forEach(async dte => {
        let dt = this.datePipe.transform(new Date(new Date().setDate(new Date().getDate()-2)), 'yyyy-MM-dd');

        let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + dt + ';operator_id:39;status:PAID&limit=0&searchJoin=and&searchFields=status:=&include=user');
        this.flutter48_pending = 0;
        this.flutter48_pending_data = snapshots.data;
        snapshots.data.forEach(async (d: any) => {
            this.flutter48_pending += Math.round(parseFloat(d.converted_amount));
        });
        this.flutter48_pending_fees = Math.round(0.003 * this.flutter48_pending);
        console.log(this.flutter48_pending);
        console.log(this.flutter48_pending_fees);
        console.log(this.flutter48_pending_data);

    }

    async getDigipayTransactions() {
        //this.dates.forEach(async dte => {
        let dt = this.datePipe.transform(new Date(new Date().setDate(new Date().getDate()-1)), 'yyyy-MM-dd');

        let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + dt + ';operator_id:44;status:PAID&limit=0&searchJoin=and&searchFields=status:=&include=user');
        this.digipay_pending = 0;
        this.digipay_pending_data = snapshots.data;
        snapshots.data.forEach(async (d: any) => {
            this.digipay_pending += Math.round(parseFloat(d.converted_amount));
        });
        this.digipay_pending_fees = Math.round(0.003 * this.digipay_pending);
        console.log(this.digipay_pending);
        console.log(this.digipay_pending_fees);
        console.log(this.digipay_pending_data);

    }


    async fedaPayDoDepot() {
        //benificiary.data.name, benificiary.data.numberWithCode
        try {

            for (const transaction of this.fedapay_pending_data) {
                let depot_request = await this.performFedapayPOSTPromise('https://api.fedapay.com/v1/payouts', {
                    'amount': transaction.converted_amount,
                    'currency': {'iso': 'XOF'},
                    'mode': 'mtn',
                    'customer': {
                        'firstname': transaction.benificiary.data.name,
                        'lastname': transaction.benificiary.data.name,
                        'email': transaction.user.data.email,
                        'phone_number': {
                            'number': transaction.benificiary.data.numberWithCode,
                            'country': 'bj'
                        }
                    }
                });
                let payout = await this.performFedapayPUTPromise('https://api.fedapay.com/v1/payouts/start', {
                    'payouts': [
                        {'id': depot_request['v1/payout'].id}
                    ]
                });
                let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
                    status: 'SUCCESS',
                    transaction_id: depot_request['v1/payout'].id
                });
                console.log("send successfully : ", transaction);
            }

        } catch (e) {
            console.log(e);
            this.api.handleErrors("une erreur s'est produit");
        }

    }

    async paydunyaDoDepot(partner) {
        //benificiary.data.name, benificiary.data.numberWithCode

        if(partner == 'fluter24'){
            for (const transaction of this.flutter24_pending_data) {

                try{
                    let depot_request = await this.performFedapayPOSTPromise('https://app.paydunya.com/api/v1/direct-pay/credit-account', {
                        'account_alias': transaction.benificiary.data.phoneNumber,
                        'amount': Math.round(transaction.converted_amount),
                        'withdraw_mode': 'mtn-benin',
                    });
                    let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
                        status: 'SUCCESS',
                        transaction_id: depot_request?.transaction_id,
                        provider_ref: depot_request?.provider_ref,
                    });
                    console.log("send successfully : ", transaction);

                } catch (e) {
                    console.log(e);
                    this.api.handleErrors("une erreur s'est produit");
                }
            }
        }
        else if(partner == 'fluter48'){
            for (const transaction of this.flutter48_pending_data) {

                try{
                    let depot_request = await this.performFedapayPOSTPromise('https://app.paydunya.com/api/v1/direct-pay/credit-account', {
                        'account_alias': transaction.benificiary.data.phoneNumber,
                        'amount': Math.round(transaction.converted_amount),
                        'withdraw_mode': 'mtn-benin',
                    });
                    let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
                        status: 'SUCCESS',
                        transaction_id: depot_request?.transaction_id,
                        provider_ref: depot_request?.provider_ref,
                    });
                    console.log("send successfully : ", transaction);

                } catch (e) {
                    console.log(e);
                    this.api.handleErrors("une erreur s'est produit");
                }
            }
        }
        else if(partner == 'digipay'){
            for (const transaction of this.digipay_pending_data) {

                try{
                    let depot_request = await this.performFedapayPOSTPromise('https://app.paydunya.com/api/v1/direct-pay/credit-account', {
                        'account_alias': transaction.benificiary.data.phoneNumber,
                        'amount': Math.round(transaction.converted_amount),
                        'withdraw_mode': 'mtn-benin',
                    });
                    let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + transaction.id, {
                        status: 'SUCCESS',
                        transaction_id: depot_request?.transaction_id,
                        provider_ref: depot_request?.provider_ref,
                    });
                    console.log("send successfully : ", transaction);

                } catch (e) {
                    console.log(e);
                    this.api.handleErrors("une erreur s'est produit");
                }
            }
        }


    }

    async calcGainsv1() {
        console.log(this.date);
        this.afs
            .collection('transactions', (req) =>
                req.where('date2', '==', this.date)
                    .where('status', '==', 'SUCCESS')
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
                this.transfered_v1 = 0;
                snapshots.forEach(async (d: any) => {
                    //this.gains += parseFloat(d.converted_amount);
                    this.transfered_v1 += parseFloat(d.converted_amount);
                });
                this.gains_v1 = Math.round(0.015 * this.transfered_v1);
                // console.log(this.histories);
            });

        //this.pending_v1 = 0;
        let snapshots = await this.api.getTransactions(this.date);
        snapshots.forEach(async (d: any) => {
            const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${d.reference_paydunya}`);

            //console.log(paydunya_response);
            //console.log(paydunya_response?.status);
            d.paydunya_status = paydunya_response?.status;

        });
        this.histories = snapshots;
        /*snapshots.forEach((d:any) => {
            if(d.paydunya_status == "completed" && d.status != 'SUCCESS')
                this.pending_v1 += parseInt(d.converted_amount);
        });
        this.fees_v1 = this.pending_v1 * 0.008;*/

    }


    async calcGainsv2_new() {
        console.log(this.date2);
        this.data = [];
        this.dates.forEach(async dte => {
            let dd = {
                transfered: 0,
                gains: 0,
                pending: 0,
                fees: 0,
                date: dte
            };
            let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + dte + ';status:SUCCESS&limit=0&searchJoin=and&searchFields=status:=');
            dd.transfered = 0;
            snapshots.data.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                dd.transfered += Math.round(parseFloat(d.converted_amount));
            });
            dd.gains = Math.round(0.015 * dd.transfered);

            //https://api.paymentss.cosna-afrique.com/v1/transactions/stats/date?date=2021-12-03&pending=true
            let snapshots2 = await this.performGetPromise(this.base_url + 'transactions/stats/date?date=' + dte + '&pending=true&limit=0');
            dd.pending = 0;
            snapshots2.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                dd.pending += Math.round(parseFloat(d.converted_amount));
            });
            dd.fees = Math.round(0.001 * dd.pending);
            this.data.push(dd);
        });

        /*this.afs
            .collection('transactions', (req) =>
                req.where('date2', '==', this.date)
                    .where('status', '==', 'SUCCESS')
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
              this.transfered = 0;
              snapshots.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                this.transfered += parseFloat(d.converted_amount);
              });
              this.gains = Math.round(0.022 * this.transfered);
              // console.log(this.histories);
            });*/
    }

    async getData(): Promise<any> {
        /*return new Promise<any>(async (resolve, reject) => {
            let snapshots_eur = await this.performGetPromise(this.base_url +"transactions?search=created_at:"+this.date2+";status:SUCCESS;currency:EUR&limit=0&searchJoin=and&searchFields=status:=");
            let snapshots_usd = await this.performGetPromise(this.base_url +"transactions?search=created_at:"+this.date2+";status:SUCCESS;currency:USD&limit=0&searchJoin=and&searchFields=status:=");
            let data = {
                eur : 0,
                usd : 0,
            };
            snapshots_eur.data.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                data.eur += Math.round(parseFloat(d.converted_amount));
            });
            snapshots_usd.data.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                data.usd += Math.round(parseFloat(d.converted_amount));
            });
            resolve(data);
        });*/
    }

    async calcRateGains() {
        //let data:any = await this.api.performGetPromise("https://api.fastforex.io/convert?from=XAF&to=EUR&amount="+this.transfered_v2+"&api_key=d1e58e5969-fc3ca7754a-r5x7zz", null);
        //console.log(this.new_rate);

        //let data = await this.getData();
        //this.charge_gains = Math.round((data.eur/650) * (this.new_rate - 650)) + Math.round((data.usd/520) * (this.new_rate_usd - 520)) ;
    }

    async calcGainsv2() {
        console.log(this.date2);
        //this.dates.forEach(async dte => {

        let snapshots = await this.performGetPromise(this.base_url + 'amount/transfered?date=' + this.date2+"&to="+this.date3 );
        /*
        [
            {
                "total_amount": 57140506,
                "xaf": 603000,
                "xof": 14004306,
                "usd": 96000,
                "eur": 42437200
            }
        ]
         */
        this.transfered_v2 = snapshots[0].total_amount;
        this.data_curr_eur = snapshots[0].eur;
        this.data_curr_usd = snapshots[0].usd;
        /*snapshots.data.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            this.transfered_v2 += Math.round(parseFloat(d.converted_amount));
            if (d.currency == 'EUR') {
                this.data_curr_eur += Math.round(parseFloat(d.converted_amount));
            }
            if (d.currency == 'USD') {
                this.data_curr_usd += Math.round(parseFloat(d.converted_amount));
            }
        });*/
        //console.log("eur",this.data_curr_eur);
        //console.log("eur_rate",(this.new_rate - 650));
        //console.log("usd",this.data_curr_usd);
        //console.log("usd_rate",(this.new_rate_usd - 520));
        this.charge_gains = 0;//Math.round((this.data_curr_eur / this.our_eur_rates) * (this.new_rate - this.our_eur_rates)) + Math.round((this.data_curr_usd / this.our_usd_rates) * (this.new_rate_usd - this.our_usd_rates));
        this.gains_v2 = snapshots[0].gain;//Math.round((this.data_curr_eur / this.our_eur_rates) * (this.new_rate - this.our_eur_rates)) + Math.round((this.data_curr_usd / this.our_usd_rates) * (this.new_rate_usd - this.our_usd_rates));
        this.gain_eur = Math.round((this.data_curr_eur / this.our_eur_rates) * (this.new_rate - this.our_eur_rates));
        this.gain_usd = Math.round((this.data_curr_usd / this.our_usd_rates) * (this.new_rate_usd - this.our_usd_rates));
        //this.gains_v2 = Math.round(0.025 * snapshots[0].total_not_premium) + Math.round(0.017 * snapshots[0].total_premium);

        //https://api.paymentss.cosna-afrique.com/v1/transactions/stats/date?date=2021-12-03&pending=true
        //let snapshots2 = await this.performGetPromise(this.base_url + 'transactions/stats/date?date=' + this.date2 + '&pending=true&limit=0');
        this.pending_v2 = snapshots[0].pending_amount == null ? 0 : snapshots[0].pending_amount;

        /*snapshots2.forEach(async (d: any) => {
            //this.gains += parseFloat(d.converted_amount);
            this.pending_v2 += Math.round(parseFloat(d.converted_amount));
        });*/
        this.fees_v2 = Math.round(0.005 * this.pending_v2);


        //});
        //this.calcRateGains();

        /*this.afs
            .collection('transactions', (req) =>
                req.where('date2', '==', this.date)
                    .where('status', '==', 'SUCCESS')
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
              this.transfered = 0;
              snapshots.forEach(async (d: any) => {
                //this.gains += parseFloat(d.converted_amount);
                this.transfered += parseFloat(d.converted_amount);
              });
              this.gains = Math.round(0.022 * this.transfered);
              // console.log(this.histories);
            });*/
    }


    numberWithSpaces(x) {
        var parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return parts.join('.');
    }


    //SERVICE

    performGetPromise(url) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.get(url, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performPATCHPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.patch(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performPOSTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performFedapayPOSTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performFedapayPUTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.put(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }





    /**
    histories: any[];
    xof_balance = 0;
    xaf_balance = 0;
    users = [];
    res = [];
    show_all = true;

    is_sending = false;

    amount1 = 0;
    amount2 = 0;
    date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    id: any;
    country: any = 'bj';
    phoneNumber: any;
    converted_amount: any;
    operator: any = 'mtn-benin';
    reference_paydunya: any;

    gains = 0;
    transfered = 0;
    fees = 0;
    pending = 0;

    ref = null;
    ref2 = null;

    page = 1;
    total_page = 0;
    page_for = 'all';


    base_url = 'https://api.payments.cosna-afrique.com/v1/';
    ref3 = null;
    ref4 = null;
    filter: any;
    data: any = [];

    constructor(
        public afs: AngularFirestore,
        public api: ApiService,
        public datePipe: DatePipe,
        private http: HttpClient
    ) {
    }

    async ngOnInit() {
        let fileds = [];
        this.load();

        //this.calcGains();


        let arr = [];
        this.res = [];
        let sele = '';
        arr.forEach(async a => {
            //sele += "select * from `transactions` where `transaction_id`='"+a+"';";
            setTimeout(async () => {
                    let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=transaction_id:' + a + '&searchFields=transaction_id:=');
                    if (snapshots.data.length > 0) {
                        const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${snapshots.data[0].reference_paydunya}`);
                        this.res.push({'id': a, 'found': snapshots.data[0].reference_paydunya, 'status': paydunya_response?.status});
                    } else {
                        this.res.push({'id': a, 'found': false});
                    }
                },
                10000);


        });
        console.log(this.res);
        console.log(arr.length);
        console.log(sele);
    }

    async calcGains() {


    }

    async search(filter, starting = true) {
        let snapshots: any = null;
        console.log('new version');
        this.filter = filter;
        if (filter == 'id') {
            this.page_for = 'id';
            snapshots = await this.performGetPromise(this.base_url + 'transactions/' + this.ref + '?include=user');
            snapshots.data = [snapshots.data];
        } else if (filter == 'user_id') {
            this.page_for = 'user';
            if (starting) {
                this.page = 1;
            }
            snapshots = await this.performGetPromise(this.base_url + 'transactions?include=user&search=user_id:' + this.ref3 + '&searchFields=user_id:=&page=' + this.page + '&limit=50');
            this.total_page = snapshots.meta.pagination.total_pages;
        } else if (filter == 'reference_paydunya') {
            this.page_for = 'reference';
            snapshots = await this.performGetPromise(this.base_url + 'transactions?include=user&search=paydunya_reference:' + this.ref2 + '&searchFields=paydunya_reference:like');
        } else {
            console.log(this.ref4);
            this.page_for = 'depot_reference';
            snapshots = await this.performGetPromise(this.base_url + 'transactions?include=user&search=transaction_id:' + this.ref4 + '&searchFields=transaction_id:like');
        }
        console.log(snapshots);
        snapshots.data.forEach(async (d: any) => {
            const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${d.reference_paydunya}`);
            d.paydunya_status2 = paydunya_response?.status;
        });
        this.histories = snapshots.data;
    }

    async load(all = true) {


        //this.calcGains();

        this.page_for = 'all';
        //&orderBy=updated_at&sortedBy=desc
        //;paydunya_status:completed;status:DEPOT_FAILED
        let snapshots = await this.performGetPromise(this.base_url + 'transactions?include=user&search=created_at:' + this.date + '&limit=50&page=' + this.page + '&searchJoin=and&orderBy=updated_at&sortedBy=desc');
        console.log(snapshots);
        this.total_page = snapshots.meta.pagination.total_pages;
        //let snapshots = await this.api.getTransactions(this.date);
        snapshots.data.forEach(async (d: any) => {
            const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${d.reference_paydunya}`);

            //console.log(paydunya_response);
            //console.log(paydunya_response?.status);
            d.paydunya_status2 = paydunya_response?.status;
        });
        this.histories = snapshots.data;

    }

    async autoSend(all = true) {


        //this.calcGains();

        this.data = [];
        //this.page_for = 'all';
        //&orderBy=updated_at&sortedBy=desc
        //let snapshots = await this.performGetPromise(this.base_url + 'transactions?search=created_at:' + this.date + ';paydunya_status:completed;country_id:2&limit=0&searchJoin:and');
        //console.log(snapshots);
        //this.total_page = snapshots.meta.pagination.total_pages;
        //let snapshots = await this.api.getTransactions(this.date);
        //id, country, phoneNumber, converted_amount, operator
        this.histories.forEach(async (d: any) => {
            if(d.paydunya_status == 'completed' && d.status!='SUCCESS'){
                this.doDunyaDepotOnly(d.id, d.reference_paydunya, d.country_id, this.api.replaceAll(d.phoneNumber, ' ',''), d.converted_amount, 'mtn-benin');
            }
            //const paydunya_response = await this.api.performGetPromisePayDunya(`https://app.paydunya.com/api/v1/checkout-invoice/confirm/${d.reference_paydunya}`);

            //console.log(paydunya_response);
            //console.log(paydunya_response?.status);
            //d.paydunya_status2 = paydunya_response?.status;
        });
        //this.histories = snapshots.data;

    }

    numberWithSpaces(x) {
        var parts = x.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return parts.join('.');
    }

    setAllToSuccess() {
        this.afs
            .collection('users', (req) =>
                req.where('canSend', '==', true)
            )
            .valueChanges({idField: 'id'})
            .subscribe((snapshots) => {
                //this.histories = snapshots;
                // console.log(this.histories);
                snapshots.forEach(async (d: any) => {
                    await this.afs.collection('users').doc(d.id).update({
                        canSend: false
                    }).then(() => {
                        console.log('done');
                    });

                });
            });
    }

    async changestatus(id) {
        await this.afs.collection('users').doc(id).update({
            canResend: 'no'
        }).then(() => {
            console.log('done');
        });
    }


    private async doDunyaDepot(id, country, phoneNumber, converted_amount, operator) {
        this.is_sending = true;

        let flutter = {
            'account_alias': phoneNumber,
            'amount': Math.round(converted_amount),
            'withdraw_mode': operator,


        };
        //if (operator != "mtn") {
        //{ "accountIdentifier": "237678516035", "amount": "40000",
        //   "providerIdentifier": "62401", "referenceOrder": "12236543874" }
        if(operator == 'diol_om' || operator == 'diol_mtn'){
            this.api.performPostPromiseDiool('https://core.diool.com/core/dioolapi/v1/transfer', {
                "accountIdentifier": "237"+phoneNumber, "amount": Math.round(converted_amount),
                "providerIdentifier" : operator == 'diol_om' ?  "62402" : '62401',
                "referenceOrder": new Date().getMilliseconds()
            }).then(
                async res => {


                    ////this.in_progress = false;
                    if (res.resultType == 'SUCCESS') {

                        let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + this.id, {
                            status: 'SUCCESS',
                            transaction_id: res?.result.transferRef,
                            provider_ref: res?.result.extRecipientTransactionRef,
                            phoneNumber: phoneNumber.toString(),
                        });
                        console.log(snapshots);
                        this.is_sending = false;
                        if (this.page_for == 'all') {
                            console.log('all');
                            this.load();
                        } else {
                            console.log('filter');
                            this.search(this.filter);
                            alert('Transaction terminée avec succès');
                        }

                    } else {
                        this.is_sending = false;
                        alert('Transaction échoué!');
                    }


                },
                async err => {

                    this.is_sending = false;

                    this.api.handleErrors('Transaction échoué!');

                });
        }
        else {
            this.api.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', flutter).then(
                async res => {


                    ////this.in_progress = false;
                    if (res.response_code == '00') {

                        let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + this.id, {
                            status: 'SUCCESS',
                            transaction_id: res?.transaction_id,
                            provider_ref: res?.provider_ref,
                            phoneNumber: phoneNumber.toString(),
                        });
                        console.log(snapshots);
                        this.is_sending = false;
                        if (this.page_for == 'all') {
                            console.log('all');
                            this.load();
                        } else {
                            console.log('filter');
                            this.search(this.filter);
                            alert('Transaction terminée avec succès');
                        }

                    } else {
                        this.is_sending = false;
                        alert('Transaction échoué!');
                    }


                },
                async err => {

                    this.is_sending = false;

                    this.api.handleErrors('Transaction échoué!');

                });
        }

    }

    private async doDunyaDepotOnly(id, ref, country, phoneNumber, converted_amount, operator) {
        this.is_sending = true;

        let flutter = {
            'account_alias': phoneNumber,
            'amount': Math.round(converted_amount),
            'withdraw_mode': operator,


        };
        //if (operator != "mtn") {
        if(operator == 'diol_om' || operator == 'diol_mtn'){
            this.api.performPostPromiseDiool('https://core.diool.com/core/dioolapi/v1/transfer', {
                "accountIdentifier": "237"+phoneNumber, "amount": Math.round(converted_amount),
                "providerIdentifier" : operator == 'diol_om' ?  "62402" : '62401',
                "referenceOrder": new Date().getMilliseconds()
            }).then(
                async res => {


                    ////this.in_progress = false;
                    if (res.resultType == 'SUCCESS') {

                        let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + id, {
                            status: 'SUCCESS',
                            transaction_id: res?.result.transferRef,
                            provider_ref: res?.result.extRecipientTransactionRef,
                            phoneNumber: phoneNumber.toString(),
                        });
                        this.data.push({ref: id, amount : converted_amount, status : 'SUCCESS'});
                        console.log(snapshots);
                        //alert('Transaction terminée avec succès');


                    } else {
                        this.data.push({ref: id, amount : converted_amount, status : 'FAILED'});
                        this.is_sending = false;
                        //alert('Transaction échoué!');
                    }


                },
                async err => {
                    this.data.push({ref: id, amount : converted_amount, status : 'FAILED'});

                    this.is_sending = false;

                    this.api.handleErrors('Transaction échoué!');

                });
        }
        else {
            this.api.performPostPromisePayDunya('https://app.paydunya.com/api/v1/direct-pay/credit-account', flutter).then(
                async res => {


                    ////this.in_progress = false;
                    if (res.response_code == '00') {

                        let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + id, {
                            status: 'SUCCESS',
                            transaction_id: res?.transaction_id,
                            provider_ref: res?.provider_ref,
                            phoneNumber: phoneNumber.toString(),
                        });
                        console.log(snapshots);
                        this.is_sending = false;
                        this.data.push({ref: ref, amount : converted_amount, status : 'SUCCESS'});
                        //alert('Transaction terminée avec succès');


                    } else {
                        this.data.push({ref: ref, amount : converted_amount, status : 'FAILED'});
                        this.is_sending = false;
                        //alert('Transaction échoué!');
                    }


                },
                async err => {
                    this.data.push({ref: ref, amount : converted_amount, status : 'FAILED'});

                    this.is_sending = false;

                    this.api.handleErrors('Transaction échoué!');

                });
        }

    }


    async fedaPayDoDepot(id, country, phoneNumber, converted_amount, operator) {
        //benificiary.data.name, benificiary.data.numberWithCode
        try {
            let depot_request = await this.performFedapayPOSTPromise('https://api.fedapay.com/v1/payouts', {
                'amount': converted_amount,
                'currency': {'iso': 'XOF'},
                'mode': 'mtn',
                'customer': {
                    'firstname': '',
                    'lastname': '',
                    'email': '',
                    'phone_number': {
                        'number': '+237' + phoneNumber,
                        'country': 'bj'
                    }
                }
            });
            let payout = await this.performFedapayPUTPromise('https://api.fedapay.com/v1/payouts/start', {
                'payouts': [
                    {'id': depot_request['v1/payout'].id}
                ]
            });
            let snapshots = await this.performPATCHPromise(this.base_url + 'transactions/' + id, {
                status: 'SUCCESS',
                phoneNumber: phoneNumber.toString(),
                transaction_id: depot_request['v1/payout'].id
            });
            this.is_sending = false;
            if (this.page_for == 'all') {
                console.log('all');
                this.load();
            } else {
                console.log('filter');
                this.search(this.filter);
                alert('Transaction terminée avec succès');
            }


        } catch (e) {
            this.is_sending = false;

            this.api.handleErrors('Transaction échoué!');
            console.log(e);
        }

    }

    resend(history: any) {
        //this.country = history.country;
        this.id = history.id;
        this.converted_amount = parseInt(history.converted_amount);
        //this.operator = history.operator;
        this.reference_paydunya = history.reference_paydunya;
        window.scrollTo({top: 0, behavior: 'smooth'});
    }


    //SERVICE

    performGetPromise(url) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.get(url, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performPATCHPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Referer': 'https://cosna-afrique.com',
            'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.patch(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performPOSTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Referer': 'https://cosna-afrique.com',
            'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    goToPage(page: number) {
        this.page = page;
        if (this.page_for == 'all') {
            this.load();
        } else if (this.page_for == 'user') {
            this.search('user_id', false);
        }
    }


    performFedapayPOSTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.post(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }

    performFedapayPUTPromise(url, data) {
        let headerDict = {
            'Accept': 'application/json',
            'Content-type': 'application/json',
            'Authorization': 'Bearer sk_live_eeEACzNiFIrHMNSCyjxBFAOS'
            //'Referer': 'https://cosna-afrique.com',
            //'Origin': 'https://cosna-afrique.com',
        };
        const reqOptions = {
            headers: new HttpHeaders(headerDict),
        };
        return new Promise<any>((resolve, reject) => {
            this.http.put(url, data, reqOptions).subscribe(result => {
                resolve(result);
            }, err => {
                reject(err);
            });
        });
    }
    */
    protected readonly parseInt = parseInt;
}
